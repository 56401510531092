document.addEventListener("DOMContentLoaded", function() {
  if (document.querySelector(".js-nav-circle") === null) return;

  const HOVER_CLASS = "is-hovered";
  const navCircleItems = document.querySelector(".js-nav-circle");

  navCircleItems.addEventListener("mouseover", addHoverStateFromCircleNav);
  navCircleItems.addEventListener("mouseout", removeHoverStateFromCircleNav);

  function addHoverStateFromCircleNav(e) {
    if (!e.target.closest(".js-circle-text")) return;
    e.target
      .closest(".js-circle-text")
      .previousElementSibling.classList.add(HOVER_CLASS);
  }

  function removeHoverStateFromCircleNav(e) {
    if (!e.target.closest(".js-circle-text")) return;
    e.target
      .closest(".js-circle-text")
      .previousElementSibling.classList.remove(HOVER_CLASS);
  }
});

import "https://unpkg.com/sweet-scroll/sweet-scroll.min.js";

// console.log(window.navigator.userLanguage || window.navigator.languages);

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".home-page") === null) return;

  let img1 = document.querySelector(
    ".slide-people .swiper-slide__img-container img"
  );
  let img2 = document.querySelector(
    ".slide-result .swiper-slide__img-container img"
  );
  let el1rect, el2rect, el1x, el1y, el2x, el2y;

  // set line width in main swiper
  setLineWidthBetweenSlideImages();
  // what we do section - arrow behavior on link hover
  // hoverEffectArrow();

  window.addEventListener("resize", function() {
    console.log("fdfd");
    setLineWidthBetweenSlideImages();
  });

  function getDistanceBetweenSlideImages(el1, el2) {
    el1rect = el1.getBoundingClientRect();
    el2rect = el2.getBoundingClientRect();
    el1x = el1rect.left + el1rect.width / 2;
    el1y = el1rect.top + el1rect.height / 2;
    el2x = el2rect.left + el2rect.width / 4;
    el2y = el2rect.top + el2rect.height / 2;

    // calculate the distance using the Pythagorean Theorem (a^2 + b^2 = c^2)
    let distanceSquared = Math.pow(el1x - el2x, 2) + Math.pow(el1y - el2y, 2);
    return Math.sqrt(distanceSquared);
  }

  function setLineWidthBetweenSlideImages() {
    document.documentElement.style.setProperty(
      "--line-distance",
      getDistanceBetweenSlideImages(img1, img2) + "px"
    );
  }
});

const insightsListValuesRu = [
  {
    title: "Смягчение валютных ограничений для физических лиц",
    desc:
      "В апреле на сайте Банка России появилась важная новость для физических лиц. В этом году Международный день смеха запомнится, в том числе снятием некоторых валютных ограничений. Регулятор принял решение установить на ближайшее 6 месяцев новые пороги по суммам переводов средств за рубеж.",
    id: 10,
    link:
      "insights/smyagchenie-valyutnyh-ogranichenij-dlya-fizicheskih-lic.html",
    image:
      "../../images/insights/smyagchenie-valyutnyh-ogranichenij-dlya-fizicheskih-lic.jpeg"
  },
  {
    title:
      "Санкционное законодательство, затрагивающее частных клиентов, в вопросах и ответах (по состоянию на 17 марта 2022)",
    desc:
      "Рассказываем о новых правилах операций с валютными счетами для физических и юридических лиц в формате ответов на самые популярные вопросы",
    id: 9,
    link:
      "insights/sankcionnoe-zakonodatelstvo-zatragivayushchee-chastnyh-klientov-v-voprosah-i-otvetah-po-sostoyaniyu-na-17-marta-2022.html",
    image:
      "../../images/insights/sankcionnoe-zakonodatelstvo-zatragivayushchee-chastnyh-klientov-v-voprosah-i-otvetah-po-sostoyaniyu-na-17-marta-2022.jpeg"
  },
  {
    title: "Новые правила игры для бизнеса и&nbsp;частного капитала",
    desc:
      "В&nbsp;связи с&nbsp;сохранением напряженной политической ситуации ежедневно меняются правила игры для бизнеса и&nbsp;частного капитала. По&nbsp;состоянию на&nbsp;03&nbsp;марта 2022 введены новые ограничения, имеющие далеко идущие последствия.",
    id: 8,
    link: "insights/novye-pravila-igry-dlya-biznesa-i-chastnogo-kapitala.html",
    image:
      "../../images/insights/novye-pravila-igry-dlya-biznesa-i-chastnogo-kapitala.jpg"
  },
  {
    title: "Санкции в&nbsp;отношении граждан&nbsp;РФ",
    desc:
      "По&nbsp;состоянию на&nbsp;25&nbsp;февраля 2022 окончательный объем санкций и&nbsp;их&nbsp;детали пока еще не&nbsp;ясны. Однако в&nbsp;текущей ситуации появились новый вид санкций, которые непосредственным образом сузят возможности россиян, привыкшим к&nbsp;открытым международным границам.",
    id: 7,
    link: "insights/sankcii-v-otnoshenii-grazhdan-rf.html",
    image: "../../images/insights/sankcii-v-otnoshenii-grazhdan-rf.jpeg"
  },
  {
    title: "Кипр для профессионалов&nbsp;&mdash; почему это выгодно?",
    desc:
      "Помимо инвестиционных программ, с&nbsp;2017 года кипрское налоговое законодательство предусматривает упрощенный порядок признания лиц, осуществляющих профессиональную деятельность на&nbsp;острове, в&nbsp;качестве налоговых резидентов Кипра...",
    id: 6,
    link: "insights/kipr-dlya-professionalov-pochemu-ehto-vygodno.html",
    image:
      "../../images/insights/kipr-dlya-professionalov-pochemu-ehto-vygodno.jpeg"
  },
  {
    title:
      "Что такое &laquo;домициль&raquo; и&nbsp;почему важно о&nbsp;нем помнить при смене налогового резидентства?",
    desc:
      "Проживание и&nbsp;наличие недвижимости в&nbsp;разных странах почти всегда влекут возникновение вопроса смены налогового резидентства. Важно понимать, какое государство будет облагать налогом доходы лица в&nbsp;случае, если у&nbsp;такого лица есть привязка к&nbsp;двум и&nbsp;более юрисдикциям. При этом вопрос домициля не&nbsp;всегда попадает в&nbsp;поле зрения сразу. Что&nbsp;же понимается под &laquo;домицилем&raquo;...",
    id: 5,
    link:
      "insights/chto-takoe-domicil-i-pochemu-vazhno-o-nem-pomnit-pri-smene-nalogovogo-rezidentstva.html",
    image:
      "../../images/insights/chto-takoe-domicil-i-pochemu-vazhno-o-nem-pomnit-pri-smene-nalogovogo-rezidentstva.jpeg"
  },
  {
    title:
      "Гамбит королевы: правила владения жилой недвижимостью в&nbsp;Великобритании",
    desc:
      "С&nbsp;2017 года налоговое законодательство Великобритании существенным образом изменилось. Нововведения коснулись различных зарубежных структур, используемых для целей владения жилой недвижимостью в&nbsp;Великобритании.",
    id: 4,
    link:
      "insights/gambit-korolevy-pravila-vladeniya-zhiloj-nedvizhimostyu-v-velikobritanii.html",
    image:
      "../../images/insights/gambit-korolevy-pravila-vladeniya-zhiloj-nedvizhimostyu-v-velikobritanii.jpeg"
  },
  {
    title: "Репатриация займов&nbsp;&mdash; о&nbsp;чем следует помнить",
    desc:
      "Валютное законодательство&nbsp;РФ содержит ряд ограничений, которые часто забывают при оформлении долгового финансирования с&nbsp;участием физических лиц или компаний&nbsp;&mdash; валютных резидентов&nbsp;РФ.",
    id: 3,
    link: "insights/repatriaciya-zajmov-o-chem-sleduet-pomnit.html",
    image:
      "../../images/insights/repatriaciya-zajmov-o-chem-sleduet-pomnit.jpeg"
  },
  {
    title:
      "Как бенефициару иностранного Траста получить имущество без уплаты налогов",
    desc:
      "Налоговое законодательство&nbsp;РФ содержит положение о&nbsp;том, что некоторые доходы, полученные от&nbsp;структур без образования юридического лица (в&nbsp;том числе, от&nbsp;Траста), не&nbsp;будут облагаться налогом у&nbsp;получателя. Для того, чтобы такое правило применялось необходимо соблюсти ряд требований",
    id: 2,
    link:
      "insights/kak-beneficiaru-inostrannogo-trasta-poluchit-imushchestvo-bez-uplaty-nalogov.html",
    image:
      "../../images/insights/kak-beneficiaru-inostrannogo-trasta-poluchit-imushchestvo-bez-uplaty-nalogov.jpeg"
  },
  {
    title:
      "Налоговые преимущества владения ценными бумагами через контролируемую иностранную компанию (КИК)",
    desc:
      "В&nbsp;связи со&nbsp;значительным колебанием курса рубля, каждый предусмотрительный инвестор&nbsp;&mdash; налоговый резидент Российской Федерации задумывался о&nbsp;возможности обезопасить свои инвестиции в&nbsp;зарубежные активы, в&nbsp;том числе, в&nbsp;ценные бумаги.",
    id: 1,
    link:
      "insights/nalogovye-preimushchestva-vladeniya-cennymi-bumagami-cherez-kontroliruemuyu-inostrannuyu-kompaniyu-kik.html",
    image:
      "../../images/insights/nalogovye-preimushchestva-vladeniya-cennymi-bumagami-cherez-kontroliruemuyu-inostrannuyu-kompaniyu-kik.jpeg"
  }
];

window.addEventListener("load", function() {
  if (document.querySelector(".insights-page") === null) return;

  let options = {
    page: 6,
    valueNames: [
      "title",
      "desc",
      { data: ["id"] },
      { name: "link", attr: "href" },
      { name: "image", attr: "src" }
    ],
    item: `<li data-id="1">
            <article class="insight">
              <a href="" class="link">
                <figure>
                  <img class="insight__image image" src="" loading="lazy"/>
                </figure>
                <h3 class="insight__title title"></h3>
                <p class="insight__desc desc"></p>
              </a>
            </article>
          </li>`,
    pagination: [
      {
        paginationClass: "insights-pagination"
      }
    ]
  };

  let insightsList = new List("insights-list", options, insightsListValuesRu);
});

const HOVER_CLASS = "is-hovered";
const MENU_SHOW_CLASS = "menu__secondary-list--show";
const LINK_ACTIVE_CLASS = "link__arrow--active";
const LINK_CURRENT_CLASS = "is-current";

const resetActiveStyles = () => {
  const activeBlock = document.querySelector(`.${MENU_SHOW_CLASS}`);
  const activeLink = document.querySelector(`.${LINK_ACTIVE_CLASS}`);
  const currentLink = document.querySelector(`.${LINK_CURRENT_CLASS}`);

  activeBlock && activeBlock.classList.remove(MENU_SHOW_CLASS);
  activeLink && activeLink.classList.remove(LINK_ACTIVE_CLASS);
  currentLink && currentLink.classList.remove(LINK_CURRENT_CLASS);
};

document.addEventListener("DOMContentLoaded", () => {
  const page = document.body;
  const button = document.querySelector(".burger-menu");
  const menu = document.querySelector(".menu");
  const logoHeader = document.querySelector(".header .header__link");

  const checkSizes = () => {
    if (document.body.clientWidth > 699) {
      desktopMenuOpener();
    } else {
      mobileMenuOpener();
    }
  };

  const toggleMenu = () => {
    button.classList.toggle("burger-menu--active");
    menu.classList.toggle("menu--active");
    if (menu.classList.contains("menu--active")) {
      document.documentElement.classList.add("is-menu-opened");
    } else {
      document.documentElement.classList.remove("is-menu-opened");
    }
    if (!menu.classList.contains("menu--active")) {
      resetActiveStyles();
      handleCurrentPage();
    }
  };

  const keydownHandler = e => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      e.preventDefault();
      toggleMenu();
    }
  };

  const primaryLinks = [...document.querySelectorAll(".js-menu-primary-link")];
  const linksListArray = [
    ...document.querySelectorAll(".secondary-list__item")
  ];

  const linkHandler = link => {
    const activeLink = document.querySelector(".link__arrow--active");
    const activeBlock = document.querySelector(`.${MENU_SHOW_CLASS}`);
    const parentList = link.parentNode;

    activeLink && activeLink.classList.remove(LINK_ACTIVE_CLASS);
    link.querySelector(".link__arrow").classList.add(LINK_ACTIVE_CLASS);

    activeBlock && activeBlock.classList.remove(MENU_SHOW_CLASS);

    linksListArray.map(item => {
      item.classList.remove(HOVER_CLASS);
    });

    parentList.querySelector(".menu__secondary-list") &&
      parentList
        .querySelector(".menu__secondary-list")
        .classList.add(MENU_SHOW_CLASS);

    [...parentList.querySelectorAll(".secondary-list__item")][0].classList.add(
      "is-hovered"
    );
  };

  const setVisibleMenuByPrimaryBlock = selector => {
    let primaryBlock = document.querySelector(selector);
    primaryBlock
      .querySelector(".secondary-list")
      .classList.add(MENU_SHOW_CLASS);
    primaryBlock
      .querySelector(".secondary-list__item")
      .classList.add(HOVER_CLASS);
    primaryBlock.querySelector(".link__arrow").classList.add(LINK_ACTIVE_CLASS);
  };

  const checkPrimaryLink = (checkLink, selector) => {
    let pageLink = window.location.href;

    if (!pageLink.includes(checkLink)) return;

    setVisibleMenuByPrimaryBlock(selector);
    return;
  };

  const moveArrow = (targetEl, arrow, leftOffset = 0) => {
    if (targetEl == undefined) {
      return;
    }
    let topPosition = targetEl.offsetTop + targetEl.offsetHeight / 2 + "px";
    arrow.setAttribute("style", `top: ${topPosition}; left: ${leftOffset}`);
  };

  const linksListHandler = e => {
    linksListArray.map(item => {
      item.classList.remove(HOVER_CLASS);
    });
    e.target.classList.add(HOVER_CLASS);
    e.target.addEventListener("mouseover", event => {
      let el = event.target;
      let link, offset;
      if (el.closest(".menu__tertiary-link")) {
        link = el.closest(".menu__tertiary-link");
        offset = 0;
      }
      if (el.closest(".menu__quaternary-link")) {
        link = el.closest(".menu__quaternary-link");
        offset = "6%";
      }
      moveArrow(link, e.target.querySelector(".menu__arrow"), offset);
    });
  };

  const handleCurrentPage = () => {
    let pageLink = window.location.href;
    let currentPageLink;

    if (pageLink.includes("insights")) {
      return;
    }

    if (!pageLink.includes("pages/")) {
      if (document.body.classList.contains("home-page")) {
        setVisibleMenuByPrimaryBlock(".js-home-page-link");
      }
      checkPrimaryLink("contacts.html", ".js-contacts-link");
      return;
    }

    if (!pageLink.includes("ru/")) {
      // get 4th string - it's current html page on EN site
      currentPageLink = pageLink.split("/")[4];
    } else {
      currentPageLink = pageLink.split("/")[5];
    }
    if (currentPageLink && currentPageLink.includes("#")) {
      currentPageLink = currentPageLink.split("#")[0];
    }

    if (menu.querySelectorAll(`[href*='${currentPageLink}']`) === null) return;

    let currentLink = [
      ...menu.querySelectorAll(
        `.menu__tertiary-link[href*='${currentPageLink}']`
      )
    ][0];
    let linkContainer = currentLink.closest(".secondary-list__item");

    currentLink.classList.add("is-current");
    currentLink.closest(".secondary-list").classList.add(MENU_SHOW_CLASS);

    currentLink
      .closest(".primary-list__item")
      .querySelector(".menu__primary-link .link__arrow")
      .classList.add(LINK_ACTIVE_CLASS);

    linkContainer.classList.add(HOVER_CLASS);

    moveArrow(currentLink, linkContainer.querySelector(".menu__arrow"));
  };

  function desktopMenuOpener() {
    handleCurrentPage();

    primaryLinks.forEach(link =>
      link.addEventListener("mouseover", () => linkHandler(link))
    );
    linksListArray.forEach(linksList => {
      linksList.addEventListener("mouseenter", linksListHandler);
    });
  }

  function mobileMenuOpener() {
    let menuAcc = document.getElementsByClassName("js-menu-primary-link");

    for (let i = 0; i < menuAcc.length; i++) {
      menuAcc[i].addEventListener("click", function(e) {
        this.classList.toggle("is-active");
        let panel = this.nextElementSibling;

        panel.classList.toggle("is-visible");
        if (panel.style.height) {
          panel.style.height = null;
        } else {
          panel.style.height = panel.scrollHeight + "px";
        }

        let menuAcc2 = panel.getElementsByClassName("secondary-list__item");
        for (let i = 0; i < menuAcc2.length; i++) {
          menuAcc2[i].addEventListener("click", function(e) {
            if (this.querySelector(".menu__secondary-link") == null) return;

            this.classList.toggle("is-active");
            let panel2 = this.querySelector(".menu__tertiary-list");

            panel2.classList.toggle("is-visible");
            if (panel2.style.height) {
              panel.style.height =
                panel.scrollHeight - panel2.scrollHeight + "px";
              panel2.style.height = null;
            } else {
              panel.style.height =
                panel.scrollHeight + panel2.scrollHeight + "px";
              panel2.style.height = panel2.scrollHeight + "px";
            }
          });
        }
      });
    }
  }

  checkSizes();

  button.addEventListener("click", toggleMenu);
  button.addEventListener("keydown", keydownHandler);

  menu.addEventListener("click", e => {
    if (!e.target.href) return;
    if (
      !window.location.href.split("#")[0].includes(e.target.href.split("#")[0])
    ) {
      return;
    }
    button.classList.remove("burger-menu--active");
    menu.classList.remove("menu--active");
    resetActiveStyles();
    handleCurrentPage();
  });

  window.addEventListener("resize", () => {
    resetActiveStyles();
    handleCurrentPage();
    checkSizes();
  });
});

/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-webp-setclasses !*/
!(function(e, n, A) {
  function o(e, n) {
    return typeof e === n;
  }
  function t() {
    var e, n, A, t, a, i, l;
    for (var f in r)
      if (r.hasOwnProperty(f)) {
        if (
          ((e = []),
          (n = r[f]),
          n.name &&
            (e.push(n.name.toLowerCase()),
            n.options && n.options.aliases && n.options.aliases.length))
        )
          for (A = 0; A < n.options.aliases.length; A++)
            e.push(n.options.aliases[A].toLowerCase());
        for (t = o(n.fn, "function") ? n.fn() : n.fn, a = 0; a < e.length; a++)
          (i = e[a]),
            (l = i.split(".")),
            1 === l.length
              ? (Modernizr[l[0]] = t)
              : (!Modernizr[l[0]] ||
                  Modernizr[l[0]] instanceof Boolean ||
                  (Modernizr[l[0]] = new Boolean(Modernizr[l[0]])),
                (Modernizr[l[0]][l[1]] = t)),
            s.push((t ? "" : "no-") + l.join("-"));
      }
  }
  function a(e) {
    var n = u.className,
      A = Modernizr._config.classPrefix || "";
    if ((c && (n = n.baseVal), Modernizr._config.enableJSClass)) {
      var o = new RegExp("(^|\\s)" + A + "no-js(\\s|$)");
      n = n.replace(o, "$1" + A + "js$2");
    }
    Modernizr._config.enableClasses &&
      ((n += " " + A + e.join(" " + A)),
      c ? (u.className.baseVal = n) : (u.className = n));
  }
  function i(e, n) {
    if ("object" == typeof e) for (var A in e) f(e, A) && i(A, e[A]);
    else {
      e = e.toLowerCase();
      var o = e.split("."),
        t = Modernizr[o[0]];
      if ((2 == o.length && (t = t[o[1]]), "undefined" != typeof t))
        return Modernizr;
      (n = "function" == typeof n ? n() : n),
        1 == o.length
          ? (Modernizr[o[0]] = n)
          : (!Modernizr[o[0]] ||
              Modernizr[o[0]] instanceof Boolean ||
              (Modernizr[o[0]] = new Boolean(Modernizr[o[0]])),
            (Modernizr[o[0]][o[1]] = n)),
        a([(n && 0 != n ? "" : "no-") + o.join("-")]),
        Modernizr._trigger(e, n);
    }
    return Modernizr;
  }
  var s = [],
    r = [],
    l = {
      _version: "3.6.0",
      _config: {
        classPrefix: "",
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0
      },
      _q: [],
      on: function(e, n) {
        var A = this;
        setTimeout(function() {
          n(A[e]);
        }, 0);
      },
      addTest: function(e, n, A) {
        r.push({ name: e, fn: n, options: A });
      },
      addAsyncTest: function(e) {
        r.push({ name: null, fn: e });
      }
    },
    Modernizr = function() {};
  (Modernizr.prototype = l), (Modernizr = new Modernizr());
  var f,
    u = n.documentElement,
    c = "svg" === u.nodeName.toLowerCase();
  !(function() {
    var e = {}.hasOwnProperty;
    f =
      o(e, "undefined") || o(e.call, "undefined")
        ? function(e, n) {
            return n in e && o(e.constructor.prototype[n], "undefined");
          }
        : function(n, A) {
            return e.call(n, A);
          };
  })(),
    (l._l = {}),
    (l.on = function(e, n) {
      this._l[e] || (this._l[e] = []),
        this._l[e].push(n),
        Modernizr.hasOwnProperty(e) &&
          setTimeout(function() {
            Modernizr._trigger(e, Modernizr[e]);
          }, 0);
    }),
    (l._trigger = function(e, n) {
      if (this._l[e]) {
        var A = this._l[e];
        setTimeout(function() {
          var e, o;
          for (e = 0; e < A.length; e++) (o = A[e])(n);
        }, 0),
          delete this._l[e];
      }
    }),
    Modernizr._q.push(function() {
      l.addTest = i;
    }),
    Modernizr.addAsyncTest(function() {
      function e(e, n, A) {
        function o(n) {
          var o = n && "load" === n.type ? 1 == t.width : !1,
            a = "webp" === e;
          i(e, a && o ? new Boolean(o) : o), A && A(n);
        }
        var t = new Image();
        (t.onerror = o), (t.onload = o), (t.src = n);
      }
      var n = [
          {
            uri:
              "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=",
            name: "webp"
          },
          {
            uri:
              "data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==",
            name: "webp.alpha"
          },
          {
            uri:
              "data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
            name: "webp.animation"
          },
          {
            uri:
              "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=",
            name: "webp.lossless"
          }
        ],
        A = n.shift();
      e(A.name, A.uri, function(A) {
        if (A && "load" === A.type)
          for (var o = 0; o < n.length; o++) e(n[o].name, n[o].uri);
      });
    }),
    t(),
    a(s),
    delete l.addTest,
    delete l.addAsyncTest;
  for (var p = 0; p < Modernizr._q.length; p++) Modernizr._q[p]();
  e.Modernizr = Modernizr;
})(window, document);

const changeElementsTheme = (selector => {
  let observerArray = [];
  return {
    init: function() {
      Array.from(
        document.querySelectorAll("[data-theme-observer-margin]")
      ).forEach(item => {
        const ioObject = (function(i, observerMargin) {
          return new IntersectionObserver(
            entries => {
              entries.forEach(entry => {
                const { target, isIntersecting } = entry;
                isIntersecting &&
                  (target.dataset.theme === "dark"
                    ? i.classList.add("dark-theme")
                    : i.classList.remove("dark-theme"));
              });
            },
            {
              rootMargin: observerMargin
            }
          );
        })(item, item.dataset.themeObserverMargin);
        observerArray.push(ioObject);
      }),
        Array.from(document.querySelectorAll(selector)).forEach(item => {
          observerArray.forEach(t => {
            t.observe(item);
          });
        });
    },
    destroy: function() {
      observerArray.forEach(i => i.disconnect()),
        (t = []),
        Array.from(
          document.querySelectorAll("[data-theme-observer-margin]")
        ).forEach(i => {
          i.classList.remove("dark-theme");
        });
    }
  };
})("[data-section]");

window.addEventListener("load", () => {
  const body = document.body;
  const scrollContainer = document.querySelector(".ss-content");
  const scrollBar = document.querySelector(".ss-scroll");
  const sectionsArray = [...document.querySelectorAll("[data-section]")];
  let scrollBarThumbBg = document.createElement("span");
  let scrollBarThumb = scrollBar.cloneNode(true);

  scrollBarThumbBg.classList.add("scroll-bar-background");
  scrollBarThumb.classList.add("scrollbar-thumb", "dark-theme");
  scrollBarThumb.dataset.themeObserverMargin = "-55% 0% -45% 0%";

  sectionsArray.map((item, i) => {
    item.appendChild(scrollBarThumbBg.cloneNode(true));
  });

  scrollContainer.appendChild(scrollBarThumb);

  changeElementsTheme.init();

  const scrollEvent = () => {
    scrollBarThumb.style.top = getComputedStyle(scrollBar).top;
    scrollBarThumb.style.height = getComputedStyle(scrollBar).height;
  };

  scrollContainer.addEventListener("scroll", scrollEvent);
  window.addEventListener("resize", scrollEvent);
  window.addEventListener("mouseenter", scrollEvent);
});

(function(root, factory) {
  if (typeof exports === "object") {
    module.exports = factory(window, document);
  } else {
    window.SimpleScrollbar = factory(window, document);
  }
})(this, function(w, d) {
  var raf =
    w.requestAnimationFrame ||
    w.setImmediate ||
    function(c) {
      return setTimeout(c, 0);
    };

  function initEl(el) {
    Object.defineProperty(el, "data-simple-scrollbar", {
      value: new SimpleScrollbar(el),
      configurable: true
    });
  }

  function unbindEl(el) {
    if (!Object.prototype.hasOwnProperty.call(el, "data-simple-scrollbar"))
      return;
    el["data-simple-scrollbar"].unBind();
    //Remove the elements property
    delete el["data-simple-scrollbar"];
  }

  // Mouse drag handler
  function dragDealer(el, context) {
    var lastPageY;

    el.addEventListener("mousedown", function(e) {
      lastPageY = e.pageY;
      el.classList.add("ss-grabbed");
      d.querySelector(".ss-content").classList.add("ss-grabbed");

      d.addEventListener("mousemove", drag);
      d.addEventListener("mouseup", stop);

      return false;
    });

    function drag(e) {
      var delta = e.pageY - lastPageY;
      lastPageY = e.pageY;

      raf(function() {
        context.el.scrollTop += delta / context.scrollRatio;
      });
    }

    function stop() {
      el.classList.remove("ss-grabbed");
      d.querySelector(".ss-content").classList.remove("ss-grabbed");
      d.removeEventListener("mousemove", drag);
      d.removeEventListener("mouseup", stop);
    }
  }

  // Constructor
  function ss(el) {
    this.target = el;
    this.content = el.firstElementChild.nextElementSibling;

    this.direction = w.getComputedStyle(this.target).direction;

    this.bar = '<div class="ss-scroll">';
    //Create a reference to the function binding to remove the event listeners
    this.mB = this.moveBar.bind(this);

    this.wrapper = d.createElement("div");
    this.wrapper.setAttribute("class", "ss-wrapper");

    this.el = d.createElement("div");
    this.el.setAttribute("class", "ss-content");

    if (this.direction === "rtl") {
      this.el.classList.add("rtl");
    }

    this.wrapper.appendChild(this.el);

    while (this.target.firstChild) {
      this.el.appendChild(this.target.firstChild);
    }
    this.target.appendChild(this.wrapper);

    this.target.insertAdjacentHTML("beforeend", this.bar);
    this.bar = this.target.lastChild;

    dragDealer(this.bar, this);
    this.moveBar();

    w.addEventListener("resize", this.mB);
    this.el.addEventListener("scroll", this.mB);
    this.el.addEventListener("mouseenter", this.mB);

    this.target.classList.add("ss-container");

    var css = w.getComputedStyle(el);
    if (css["height"] === "0px" && css["max-height"] !== "0px") {
      el.style.height = css["max-height"];
    }

    this.unBind = function() {
      //Remove event listeners
      w.removeEventListener("resize", this.mB);
      this.el.removeEventListener("scroll", this.mB);
      this.el.removeEventListener("mouseenter", this.mB);

      this.target.classList.remove("ss-container");

      //Unwrap the initial content and remove remaining wrappers
      this.target.insertBefore(this.content, this.wrapper);
      this.target.removeChild(this.wrapper);

      //Remove the bar including its drag-dealer event listener
      this.target.removeChild(this.bar);
      this.bar = null; //make way for the garbage collector
    };
  }

  ss.prototype = {
    moveBar: function(e) {
      var totalHeight = this.el.scrollHeight,
        ownHeight = this.el.clientHeight,
        _this = this;

      this.scrollRatio = ownHeight / totalHeight;

      var isRtl = _this.direction === "rtl";
      var left = isRtl
        ? _this.bar.clientWidth + 18
        : _this.bar.clientWidth * -1;

      raf(function() {
        // Hide scrollbar if no scrolling is possible
        if (_this.scrollRatio >= 1) {
          _this.bar.classList.add("ss-hidden");
        } else {
          _this.bar.classList.remove("ss-hidden");
          _this.bar.style.cssText =
            "height:" +
            Math.max(_this.scrollRatio * 100, 10) +
            "%; top:" +
            (_this.el.scrollTop / totalHeight) * 100 +
            "%";
        }
      });
    }
  };

  function initAll() {
    var nodes = d.querySelectorAll("*[ss-container]");

    for (var i = 0; i < nodes.length; i++) {
      initEl(nodes[i]);
    }
  }

  function unbindAll() {
    var nodes = d.querySelectorAll(".ss-container");

    for (var i = 0; i < nodes.length; i++) {
      unbindEl(nodes[i]);
    }
  }

  d.addEventListener("DOMContentLoaded", initAll);
  ss.initEl = initEl;
  ss.initAll = initAll;
  ss.unbindEl = unbindEl;
  ss.unbindAll = unbindAll;

  var SimpleScrollbar = ss;
  return SimpleScrollbar;
});

import Swiper from "https://unpkg.com/swiper/swiper-bundle.esm.browser.min.js";

window.addEventListener("load", () => {
  if (document.querySelector(".home-page") !== null) {
    let mainSwiper = new Swiper(".main-swiper__container", {
      direction: "horizontal",
      loop: true,
      speed: 600,
      keyboard: {
        enabled: true,
        onlyInViewport: true
      },
      navigation: {
        nextEl: ".swiper-button-next"
      }
    });

    let timelineSwiper = new Swiper(".timeline__swiper", {
      direction: "horizontal",
      centeredSlides: true,
      centeredSlidesBounds: true,
      speed: 600,
      slidesPerView: 1.65,
      spaceBetween: window.innerWidth * 0.171875,
      grabCursor: true,
      navigation: {
        prevEl: ".timeline__button-prev",
        nextEl: ".timeline__button-next"
      },
      scrollbar: {
        el: ".timeline__scrollbar",
        hide: false,
        dragSize: 20,
        dragClass: "timeline__scrollbar-drag"
      },
      breakpoints: {
        700: {
          slidesPerView: 3,
          spaceBetween: 30,
          centeredSlides: false
        },
        1190: {
          slidesPerView: 3,
          spaceBetween: 50,
          centeredSlides: false
        }
      }
    });
  }

  if (document.querySelector(".alt-invest-page") !== null) {
    const altInvestSwiper = new Swiper(
      ".alt-invest-page .swiper-container:not(.full-height)",
      {
        effect: "fade",
        fadeEffect: {
          crossFade: true
        },
        autoHeight: true,
        pagination: {
          el: ".text-swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".text-swiper-button-next",
          prevEl: ".text-swiper-button-prev"
        },
        breakpoints: {
          700: {
            fadeEffect: {
              crossFade: false
            },
            autoHeight: false
          }
        }
      }
    );
    const altInvestSwiper2 = new Swiper(
      ".alt-invest-page .swiper-container.full-height",
      {
        effect: "fade",
        fadeEffect: {
          crossFade: true
        },
        pagination: {
          el: ".full-height .text-swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".full-height .text-swiper-button-next",
          prevEl: ".full-height .text-swiper-button-prev"
        },
        breakpoints: {
          700: {
            fadeEffect: {
              crossFade: false
            }
          }
        }
      }
    );
  }

  if (document.querySelector(".maintenance-page") !== null) {
    const maintenanceTextTaxesSwiper = new Swiper("#taxes .text-swiper", {
      effect: "fade",
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: "#taxes .text-swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: "#taxes .text-swiper-button-next",
        prevEl: "#taxes .text-swiper-button-prev"
      }
    });

    const maintenanceTextLegalSupportSwiper = new Swiper(
      "#legal-support .text-swiper",
      {
        effect: "fade",
        fadeEffect: {
          crossFade: true
        },
        pagination: {
          el: "#legal-support .text-swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: "#legal-support .text-swiper-button-next",
          prevEl: "#legal-support .text-swiper-button-prev"
        }
      }
    );
  }

  if (document.querySelector(".succession-page") !== null) {
    const successionSwiper = new Swiper(
      ".succession-page .swiper-container:not(.full-height)",
      {
        effect: "fade",
        fadeEffect: {
          crossFade: true
        },
        autoHeight: true,
        pagination: {
          el: ".text-swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".text-swiper-button-next",
          prevEl: ".text-swiper-button-prev"
        },
        breakpoints: {
          700: {
            fadeEffect: {
              crossFade: false
            },
            autoHeight: false
          }
        }
      }
    );
    const successionSwiper2 = new Swiper(
      ".succession-page .swiper-container.full-height",
      {
        effect: "fade",
        fadeEffect: {
          crossFade: true
        },
        pagination: {
          el: ".full-height .text-swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".full-height .text-swiper-button-next",
          prevEl: ".full-height .text-swiper-button-prev"
        },
        breakpoints: {
          700: {
            fadeEffect: {
              crossFade: false
            }
          }
        }
      }
    );
  }

  if (document.querySelector(".team-page") !== null) {
    let partnersSwiper = new Swiper("#partners .swiper-container", {
      direction: "horizontal",
      pagination: {
        el: ".text-swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: ".text-swiper-button-next",
        prevEl: ".text-swiper-button-prev"
      }
    });
  }
});

window.addEventListener("load", function() {
  if (document.querySelector(".team-page") === null) return;

  const team = document.querySelector(".js-team");
  const arrow = team.querySelector(".arrow");

  const checkSizes = () => {
    if (document.body.clientWidth > 699) {
      desktopTeamViewer();
    } else {
      mobileTeamViewer();
    }
  };

  function desktopTeamViewer() {
    team.addEventListener("mouseover", e => {
      if (!e.target.closest(".team__member")) {
        return;
      }

      let el = e.target.closest(".team__member");
      let elId = el.getAttribute("id");
      let targetText = team.querySelector(`[aria-labelledby=${el.id}]`);

      [...team.querySelectorAll(`[aria-labelledby]`)].map(el => {
        el.classList.remove("visible");
      });

      targetText.classList.add("visible");

      let topPosition =
        el.offsetTop +
        el.querySelector(".team__member-name").offsetHeight / 2 +
        "px";

      arrow.setAttribute("style", `top: ${topPosition}`);
    });
  }

  function mobileTeamViewer() {
    let teamAcc = document.getElementsByClassName("team__member");

    for (let i = 0; i < teamAcc.length; i++) {
      teamAcc[i].addEventListener("click", function() {
        this.classList.toggle("is-active");
        let panel = this.querySelector(
          `[aria-labelledby=${this.getAttribute("id")}]`
        );
        panel.classList.toggle("visible");
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }
  }

  checkSizes();
  window.addEventListener("resize", () => {
    checkSizes();
  });
});

window.addEventListener("load", function() {
  if (document.querySelector(".wwd-slide") === null) return;

  let lines = [],
    arrows = [];
  let slideListArray = [
    ...document.querySelectorAll(".wwd-slide .wwd-slide__list")
  ];

  const setLineArrowPosition = (elem, i) => {
    let tabTextElement = document.querySelector(`[aria-labelledby=${elem.id}]`);
    let tabTextsArray = [
      ...elem.closest(".wwd-slide").querySelectorAll(".wwd-slide__text")
    ];

    for (let k = 0; k < tabTextsArray.length; k++) {
      tabTextsArray[k].classList.remove("visible");
    }

    if (!elem.ariaSelected) {
      document.querySelectorAll(".wwd-slide__list-item").forEach((item, i) => {
        item.setAttribute("aria-selected", "false");
      });

      elem.setAttribute("aria-selected", "true");
    }

    tabTextElement.classList.add("visible");
    let paddingLink = getComputedStyle(elem).paddingTop.slice(0, -2);
    let topPosition =
      elem.offsetTop +
      paddingLink * 1 +
      (elem.offsetHeight - paddingLink * 2) / 2 +
      "px";

    lines[`line-${i}`].setAttribute("style", `opacity: 1; top: ${topPosition}`);
    arrows[`arrow-${i}`].setAttribute(
      "style",
      `opacity: 1; top: ${topPosition}`
    );
  };

  const tabMouseOverBehavior = (e, i) => {
    if (!e.target.classList.contains("wwd-slide__list-item")) return;

    let elem = e.target;
    setLineArrowPosition(elem, i);
  };

  slideListArray.map((slideList, i) => {
    lines.push(`line-${i}`);
    arrows.push(`arrow-${i}`);
    lines[`line-${i}`] = slideList.parentElement.querySelector(
      ".wwd-slide__list-line"
    );
    arrows[`arrow-${i}`] = slideList.parentElement.querySelector(
      ".wwd-slide__list-arrow"
    );

    setLineArrowPosition(
      [...slideList.querySelectorAll(".wwd-slide__list-item")][0],
      i
    );

    slideList.addEventListener("mouseover", e => tabMouseOverBehavior(e, i));
  });
});
